
html
  box-sizing: border-box

*
  padding: 0
  margin: 0

*, *:before, *:after
  box-sizing: inherit

@import 'reset'
@import 'variables'
@import 'libs'

body, html, #root
  height: 100%
  min-height: 100%
  font-family: $main-font
  font-size: 14px
  -webkit-text-size-adjust: 100%
  -ms-text-size-adjust: 100%
  text-size-adjust: 100%

body
  background-color: var(--primary-light, #F5F5F5)

.EmojiPickerReact
  background: transparent !important

.EmojiPickerReact.epr-main
  border: none !important

.EmojiPickerReact .epr-category-nav
  padding: 5px

.epr-emoji-category-label
  height: 30px !important

.Toastify__progress-bar--bg
  background: var(--accent-light)

#apple-pay
  height: 50px !important
  border-radius: 9px !important

.apple-pay-btn-wrapper
  height: 50px !important
  border-radius: 9px !important
